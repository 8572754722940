const language = {
  'admin.language.hetmet': 'Đa ngôn ngữ',
  'admin.language.breadcrumb.heading': 'Danh sách các ngôn ngữ',
  'admin.language.breadcrumb.parent': 'Ngôn ngữ',
  'admin.language.noti.create-language.success': 'Thêm ngôn ngữ mới thành công!',
  'admin.language.noti.create-language.failure': 'Thêm ngôn ngữ mới không thành công! Vui lòng thử lại sau.',
  'admin.language.noti.update-language.success': 'Thay đổi ngôn ngữ mặc định thành công!',
  'admin.language.noti.update-language.failure': 'Thay đổi ngôn ngữ mặc định không thành công! Vui lòng thử lại sau.',
  'admin.language.noti.delete-language.success': 'Xoá ngôn ngữ thành công!',
  'admin.language.noti.delete-language.failure': 'Thêm ngôn ngữ không thành công! Vui lòng thử lại sau.',
  'admin.language.column.key': 'Key',
  'admin.language.column.name': 'Tên',
  'admin.language.column.default': 'Mặc định',
  'admin.language.label.add': 'Thêm ngôn ngữ',
  'admin.language.modal.delete.title': 'Xác nhận xoá ngôn ngữ!',
  'admin.language.modal.delete.description': 'Bạn chắc chắn muốn xoá ngôn ngữ này?',
  'admin.language.custom.modal.create.label.key': 'Chọn ngôn ngữ',
  'admin.language.custom.modal.create.error-msg.key.required': 'Vui lòng chọn ngôn ngữ!',
  'admin.language.custom.modal.create.error-msg.key.regex': 'Ngôn ngữ không hợp lệ!',
};

export default {
  ...language,
};
